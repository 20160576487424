(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.scroll_to = function($target) {
		var $root = $('body'),
			scroll_offset = $('.header__nav').outerHeight();

		// Are we on the glossary page?
		if ($('.glossary').length) {
			scroll_offset = $('.header').outerHeight() + $('.search-bar').outerHeight();
			scroll_offset += 40; // breathing room

			$target.velocity("scroll", {
				duration : 250,
				offset : -scroll_offset,
				easing : 'easeOutCubic',
				mobileHA : false
			});

		} else {
			$target.velocity("scroll", {
				duration : 750,
				offset : -scroll_offset,
				easing : 'easeInOutCirc',
				mobileHA : false
			});
		}
	};

})(window.jQuery, window.WS);