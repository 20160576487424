(function($, WS) {
	"use strict";

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {};
		WS = window.WS;
	}

	WS.clickable = function() {
		var my;

		my = {
			_enable: function() {
				my.selectors = {
					clickable: "[data-clickable]",
					link: "a[href]"
				};

				my.classes = {
					enabled: "clickable",
					hover: "hover",
					focus: "focus"
				};

				my.link = "";

				my._init();
			},

			_init: function() {
				var $elements = $('body').find(my.selectors.clickable);

				if ($elements.length) {
					$elements.each(function(e) {
						var $clickable = $($elements[e]);

						if ((my.link = $clickable.find(my.selectors.link)) && e.target !== my.link[0]) {
							$clickable
								.addClass(my.classes.enabled)
								.on("mouseover", function() {
									$clickable.addClass(my.classes.hover);
								})
								.on("mouseout", function() {
									$clickable.removeClass(my.classes.hover);
								});

							my.link.on("focus blur", function() {
								$clickable.toggleClass(my.classes.focus);
							});

							$clickable.on("click", function(e) {
								if ((my.link = $(this).find(my.selectors.link)) && e.target !== my.link[0]) {

									my.link[0].click();
								}
							});
						}
					});
				}

			}
		};

		my._enable();
	};

	WS.clickable();
})(window.jQuery, window.WS);
