(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.block_link = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$blocks: $('[data-block-link]')
			},

			enable: function() {
				if (my.ui.$blocks.length) {
					my.ui.$blocks.each(function() {
						$(this).on('click', function(e) {
							var $link = $(this).find('a'),
								target;

							if ($link.length === 1) {

								e.preventDefault();

								target = $link.attr('href');
								if (target) {
									window.location.href = target;
								}
							}
						});
					});
				}
			}
		};

		my.enable();
	};

	WS.block_link();

})(window.jQuery, window.WS);