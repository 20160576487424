(function($, session_storage, fontface_observer) {
	'use strict';

	var font = {
		enable: function() {
			$.each(font.fonts, function(label, family) {
				var fonts = new FontFaceObserver(family);

				if (typeof session_storage !== "undefined" && !session_storage.fonts) {
					font.load_fonts(fonts, label, true);
				} else {
					font.load_fonts(fonts, label, false);
				}
			});
		},

		fonts: {
			roboto_italic:          'robotoitalic',
			roboto_regular:         'robotoregular',
			roboto_bold:            'robotobold',
			vollkorn_regular:       'vollkornregular',
		},

		load_fonts: function(fonts, label, ss) {
			fonts.load()
			.then(function() {
				$(font.ui.selector.html).addClass('wf-' + label + '-active');

				if (ss) {
					session_storage[label] = true;
				}
			}).catch(function() {
				$(font.ui.selector.html).addClass('wf-' + label + '-inactive');

				if (ss) {
					session_storage[label] = false;
				}
			});
		},

		ui: {
			selector: {
				html: 'html'
			}
		}
	};

	font.enable();
})(window.jQuery, window.sessionStorage, window.FontFaceObserver);