(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.timeline = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$timeline_blocks: $('.timeline__block')
			},

			selectors: {
				extra: '.timeline-card__extra',
				extra_content: '.timeline-card__extra-content',
				extra_toggle: '.timeline-card__extra-toggle'
			},

			check_visible: function($block) {
				var el_top, el_bottom, screen_top, screen_bottom;

				if ($block.hasClass('active')) {
					return;
				}

				el_top = $block.offset().top;
				el_bottom = $block.offset().top + $block.outerHeight();
				screen_top = $(window).scrollTop();
				screen_bottom = $(window).scrollTop() + $(window).height();

				if((screen_bottom > el_top) && (screen_top < el_bottom)){
					$block.addClass('active');
				}
			},

			update_visible: function() {
				my.ui.$timeline_blocks.each(function() {
					my.check_visible($(this));
				});
			},

			enable: function() {
				if (!my.ui.$timeline_blocks.length) {
					return false;
				}

				//set initial visibility on blocks
				my.update_visible();

				// monitor scroll
				$(window).on('scroll', my.update_visible.throttle(100));

				// add expanding content
				my.ui.$timeline_blocks.each(function() {
					var $this = $(this),
						$extra = $this.find(my.selectors.extra),
						$toggle = $this.find(my.selectors.extra_toggle),
						$extra_content = $this.find(my.selectors.extra_content);

					$toggle.on('click', function() {
						var active = $extra.hasClass('active');
						if (active) {
							$extra_content.velocity("slideUp", {duration: 280});
							$extra.removeClass('active');
						} else {
							$extra_content.velocity("slideDown", {duration: 280});
							$extra.addClass('active');
						}
					});
				});
			}
		};

		my.enable();
	};

	WS.timeline();

})(window.jQuery, window.WS);