(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.primary_menu = function() {
		var my;

		my = {
			ui: {
				$root: $('body')
			},

			selectors: {
				wrapper: '.header__nav',
				navigation: '.primary-navigation',
				primary_trigger: '.primary-nav-trigger',
				sub_trigger: '.has-children > a',
				sub_menu: '.primary-navigation__menu-2',
				sub_menu_open: '.sub-menu-open',
				h1: '.header h1',
				glossary_top: '.heading .icon-link'
			},

			update_nav_opacity: function() {
				var scroll_pos = $(document).scrollTop(),
					$nav = my.ui.$root.find(my.selectors.wrapper),
					$title = my.ui.$root.find(my.selectors.h1),
					$glossary_top = my.ui.$root.find(my.selectors.glossary_top),
					offset = 0;

				if ($('#locationBanner').css('display') != 'none') {
					offset = 80;
				}

				if ($nav.length) {
					if ($title.length) {
						offset = $title.offset().top - $nav.outerHeight();
					}

					if ($glossary_top.length) {
						offset = $glossary_top.offset().top - $nav.outerHeight();
					}

					if (scroll_pos < offset) {
						$nav.removeClass('header__nav--solid');
					} else {
						$nav.addClass('header__nav--solid');
					}
				}
			},

			enable: function() {
				// Show/Hide menu event
				my.ui.$root.find(my.selectors.primary_trigger).on('click', function(e) {
					e.preventDefault();
					my.ui.$root.toggleClass('menu-open');
				});

				my.ui.$root.find(my.selectors.sub_trigger).on('click', function(e) {
					if($(window).width() < WS.site.breakpoints.lg) {
						var $this = $(this),
							$parent = $this.parent('li');

						if(!$parent.hasClass('sub-menu-open')) {
							e.preventDefault();

							my.ui.$root
								.find(my.selectors.sub_menu_open)
								.removeClass('sub-menu-open')
								.find('ul')
								.velocity('slideUp', {
									duration: 140,
									easing: 'easeInOutCirc'
								});

							$parent.addClass('sub-menu-open');

							$this
								.next(my.selectors.sub_menu)
								.velocity('slideDown', {
									duration: 140,
									easing: 'easeInOutCirc'
								});
						}
					}
				});

				// Solid BG on scroll
				$(window).on('scroll', my.update_nav_opacity.throttle(100));

				// set initially
				my.update_nav_opacity();
			}
		};

		my.enable();
	};

	WS.primary_menu();

})(window.jQuery, window.WS);