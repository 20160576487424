(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.header_carousel = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$carousel: $('.header__carousel'),
				$bg_carousel: $('.header__bg-carousel')
			},

			selectors: {
				member: '.team-list__member-container'
			},

			enable: function() {
				if (my.ui.$carousel.length && my.ui.$bg_carousel.length) {
					my.ui.$bg_carousel.slick({
						arrows: false,
						asNavFor: '.header__carousel',
						fade: true,
						speed: 500
					});
					my.ui.$carousel.slick({
						arrows: false,
						asNavFor: '.header__bg-carousel',
						dots: true,
						speed: 500,
						fade: true
					});
				}
			}
		};

		my.enable();
	};

	WS.header_carousel();

})(window.jQuery, window.WS);