(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.anchor_links = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$links: $('a')
			},

			enable: function() {
				// add anchor attribute
				my.ui.$links.each(function() {
					var href = $(this).attr('href');

					if (href.indexOf(window.location.href) > -1) {
						if (href.indexOf('#') > -1) {
							$(this).attr('data-anchor', '');
						}
					} else if (href.charAt(0) === '#') {
						$(this).attr('data-anchor', '');
					}
				});

				my.ui.$root.on('click', '[data-anchor]', function(e) {
					var href = $(this).attr('href');
					
					e.preventDefault();

					WS.scroll_to($(href));
				});
			}
		};

		my.enable();
	};

	WS.anchor_links();

})(window.jQuery, window.WS);