(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.background_graphs = function() {
		var my;

		my = {
			ui: {
				$content: $('.main-content'),
				$graph: $('.background-graph'),
				$window: $(window)
			},

			metrics: {},

			updatePosition: function() {
				var scroll_top = my.ui.$window.scrollTop(),
					window_bottom = my.ui.$window.height() + scroll_top,
					style = my.ui.$graph.get(0).style;

				if (scroll_top > my.metrics.fixed_top && scroll_top < my.metrics.fixed_bottom) {
					style.position = 'fixed';
					style.top = 0;
				} else if (scroll_top < my.metrics.fixed_top) {
					style.position = 'absolute';
					style.top = 0;
				} else if (scroll_top > my.metrics.fixed_bottom) {
					style.position = 'absolute';
					style.top = (my.metrics.content_height - my.metrics.graph_height) + 'px';
				}
			},

			setMetrics: function() {
				my.metrics.graph_height = my.ui.$graph.height() + parseInt(my.ui.$graph.css('marginTop'));
				my.metrics.content_height = my.ui.$content.outerHeight();
				my.metrics.fixed_top = my.ui.$content.offset().top;
				my.metrics.fixed_bottom = (my.metrics.content_height + my.metrics.fixed_top) - my.metrics.graph_height;
			},

			enable: function() {

				if (my.ui.$graph.length && my.ui.$content.length) {
					my.setMetrics();
					my.updatePosition();

					my.ui.$window.on('scroll', function() {
						my.updatePosition();
					});

					my.ui.$window.on('resize', function() {
						my.setMetrics();
					});
				}
			}
		};

		my.enable();
	};

	WS.background_graphs();

})(window.jQuery, window.WS);