(function($, WS) {
    'use strict';

    /**
     * @namespace WS
     */
    if (typeof WS === "undefined") {
        window.WS = {}; WS = window.WS;
    }

    WS.LocationBanner = function($banner) {
        var my;

        my = {
            constructor: function() {
                my.$banner = $banner;
                my.userMatchesSiteLocale = true;
                my.siteRoot = (my.$banner.data('site-root') ||
                    location.protocol + '//' + location.hostname).replace(/\/$/, '');

                if (!my.$banner.length || my.$banner.data('no-show') !== undefined) {
                    // Not showing the banner
                    return;
                }

                // Check for a locale match
                $.get(my.siteRoot + '/location-select/locale-match', function(response) {
                    my.userMatchesSiteLocale = !!response;

                    if (!my.userMatchesSiteLocale) {
                        // No match, get the list and show the banner
                        $.get(my.siteRoot + '/location-select/list', function(response) {
                            my.populateAndShow(response);
                        });
                    }
                });
            },

            /**
             * Populate the banner and show the list.
             */
            populateAndShow: function(list) {
                var $select = my.$banner.find('select[name="uid_location"]');
                var selectedValue = '';
                $select.empty();

                $(list).each(function(i, location) {
                    var value = location.uid + ',' + location.locale;

                    if (location.selected) {
                        selectedValue = value;
                    }

                    $select.append(
                        $('<option>')
                            .attr('value', value)
                            .text(location.name)
                    );
                });

                $select.val(selectedValue);
                my.$banner.addClass('location-banner--show');
            }
        }

        my.constructor();
    };
})(window.jQuery, window.WS);
