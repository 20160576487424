(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.team_list = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$lists: $('.team-list')
			},

			selectors: {
				member: '.team-list__member',
				memberContainer: '.team-list__member-container'
			},

			match_height: function(items) {
				var max_h = 0,
					member_margin = 0,
					update_heights;

				update_heights = function(items) {
					items.each(function() {
						var this_h = $(this).height();

						if (this_h > max_h) {
							max_h = this_h;
						}
					});

					member_margin = parseInt(items.first().find(my.selectors.member).css('margin-bottom'), 10);

					items.height(max_h);
					items.find(my.selectors.member).outerHeight(max_h - member_margin);
					items.attr('data-base-height', max_h);
				};

				if (items.length) {

					$(window).on('orientationchange', function() {
						update_heights(items);
					});

					$(window).on('load', function() {
						update_heights(items);
					});
				}
			},

			enable: function() {
				if (my.ui.$lists.length) {

					my.ui.$lists.each(function() {
						var $this = $(this),
							$members = $this.find(my.selectors.memberContainer);

						if ($members.length) {
							// set specific, matching, heights on items
							my.match_height($members);

							$members.each(function() {
								var $member = $(this);

								$member.on('openexpander', function() {
									var $this = $(this),
										$expander = $this.find('.team-list__member-bio-container'),
										base_height = $this.data('base-height'),
										member_name = $this.find('.team-list__member-name').text(),
										expander_height = $expander.outerHeight(), 
										full_height = (base_height + expander_height),
										scroll_offset = $('.header__nav').outerHeight(),
										active_offset = 0,
										$active_container;


									// only important if the active container is above the expanding one

									$active_container = $this.prevAll('.team-list__member-container.active');

									if ($active_container.length && WS.site.breakpoints.md) {

										// if there is an active container, get its offset so we can use it to scroll to the correct position

										active_offset = $active_container.height() - parseInt($active_container.data('base-height'), 10);
									}

									$this.addClass('active');
									// set expander height to 0 before animating
									$expander.css('height', 0);

									$this.velocity({
										height: full_height,
										scroll: 0
									}, {
										duration: 400,
										mobileHA: false
									});

									$expander.velocity({
										height: expander_height
									}, {
										duration: 400,
										mobileHA: false
									});
									

									if ($(window).width() < WS.site.breakpoints.md) {

										// if we are in one column layout we need to scroll immediately to keep the clicked item within the viewport

										$this.velocity('scroll', {
											duration: 400,
											queue: false,
											offset: -(scroll_offset + active_offset + 30)
										});

									}
									else {
										WS.scroll_to($this);
									}

									//track the view
									window.global_gat.track_event('Team Member Clicks', 'Click', member_name);
								});

								$member.on('closeexpander', function() {
									var $this = $(this),
										$expander = $(this).find('.team-list__member-bio-container'),
										base_height = $(this).data('base-height');

									$this.velocity({
										height: base_height
									}, {
										duration: 400,
										mobileHA: false
									});
									$expander.velocity({
										height: 0
									}, {
										duration: 400,
										mobileHA: false,
										complete: function() {
											$this.removeClass('active');
											$expander.height('auto');
										}
									});
								});

								$member.on('click', '.team-list__member', function(e) {
									var $this = $(this),
										$others = $members.not(this).filter('.active'),
										expanded = $member.hasClass('active');

									e.preventDefault();

									if(expanded) {
										// if the clicked item is already expanded
										// then close all expanders
										$members.filter('.active').trigger('closeexpander');
									} else {
										// otherwise close anything else open
										// then open this
										if ($others.length) {
											$others.trigger('closeexpander');

											// if we are in single column layout, we need to expand immediately to prevent weird scrolling

											if ($(window).width() < WS.site.breakpoints.md) {
												$this.trigger('openexpander');
											}
											else {
												setTimeout(function() {
													$this.trigger('openexpander');
												}, 400);	
											}											
										} else {
											$this.trigger('openexpander');
										}
									}
								});
							});
						}
					});
				}
			}
		};

		my.enable();
	};

	WS.team_list();

})(window.jQuery, window.WS);