(function($, WS) {
	// require WS utility namespace
	if (typeof WS === "undefined") {
		throw new Error("Whitespace utility namespace does not exist");
	}

	/**
	 * @namespace WS
	 */

	/**
	 * @class WS.ShareController
	 * @version 1.0.0
	 */
	WS.ShareController = function($shell, settings) {
		var cls, my;

		cls = {
			/**
			 * @function WS.ShareController#share
			 * @arg {string} network
			 * @arg {string} url
			 * @arg {Object} options
			 */
			share: function() {
				return my.share.apply(cls, arguments);
			},

			/**
			 * @function WS.ShareController#set
			 * @arg {string} network
			 * @arg {string} key
			 * @arg {*} value
			 */
			set: function() {
				return my.set.apply(cls, arguments);
			}
		};

		my = {
			enable: function() {
				settings = window.ob_set(settings || {}, {
					'share': {
						'url': location.href,
						'title': window.document.title,
						'image': null
					}
				});

				/**
				 * @member WS.ShareController#networks
				 * @property {WS.ShareController#networks.twitter} twitter
				 * @property {WS.ShareController#networks.facebook} facebook
				 * @property {WS.ShareController#networks.linkedin} linkedin
				 * @property {WS.ShareController#networks.googleplus} googleplus
				 * @property {WS.ShareController#networks.email} email
				 * @description
				 * Networks available for sharing functions.
				 * **Note** - While these networks are classes, they do not need instantiating, and can be
				 * directly referenced with their methods as part of this member.
				 * @example
				 * // sharing with facebook
				 * var sharer = new site.ShareController();
				 * // set app id
				 * sharer.set("facebook", "app_id", "12345678901234");
				 * // share the current page (using the facebook network)
				 * sharer.share("facebook", location.href, {
				 * 	'image': '/path/to/my/image.jpg'
				 * });
				 * @example
				 * // sharing with twitter (using existing class)
				 * sharer.share("twitter", location.href, {
				 *	'text': window.document.title
				 * });
				 */
				cls.networks = WS.ShareController.networks;
			},

			share: function(network, url, options) {
				options = window.ob_set(options || {}, settings.share);
				url = url || settings.share.url;

				return WS.ShareController.networks[network].share(url, options);
			},

			set: function(network, key, value) {
				return WS.ShareController.networks[network].set(key, value);
			}
		};

		my.enable();

		return cls;
	};

	/**
	 * @function
	 * @private
	 */
	WS.ShareController.set = function(settings) {
		if (!settings && typeof arguments[1] !== "object") {
			settings = {};
		}

		if (typeof arguments[1] === "object") {
			settings = window.ob_set(arguments[1], settings || {});
		} else {
			settings[arguments[1]] = arguments[2];
		}

		return settings;
	};

	WS.ShareController.networks = {
		/**
		 * @class WS.ShareController#networks.twitter
		 * @see WS.ShareController#networks
		 */
		twitter: {
			data: {
				'name': 'Twitter'
			},

			/**
			 * @member WS.ShareController#networks.twitter#settings
			 * @type {Object}
			 * @property {string} intents_endpoint='https://twitter.com/intent/'
			 */
			settings: {
				'intents_endpoint': 'https://twitter.com/intent/',
				'window': {
					'width': 600,
					'height': 300
				}
			},

			set: function() {
				this.settings = WS.ShareController.set.apply(this, [this.settings, arguments[0], arguments[1]]);
			},

			share: function(url, options, after) {
				var params, key, window_settings;

				options = window.ob_set(options || {}, {
					'text': '',
					'hashtags': '',
					'via': '',
					'in_reply_to': '',
					'related': ''
				});

				window_settings = [];
				params = [
					'url=' + window.encodeURIComponent(url)
				];

				for (key in options) {
					if (options.hasOwnProperty(key) && options[key] !== "") {
						params.push(key + '=' + window.encodeURIComponent(options[key]));
					}
				}

				url = this.settings.intents_endpoint + "tweet?" + params.join("&");

				if (this.settings.window) {
					for (key in this.settings.window) {
						if (this.settings.window.hasOwnProperty(key)) {
							window_settings.push(key + "=" + this.settings.window[key]);
						}
					}
				}

				window.open(url, "twittershare", window_settings.join(","));
			}
		},

		/**
		 * @class WS.ShareController#networks.facebook
		 * @see WS.ShareController#networks
		 * @description
		 * Sharer class for sharing data on Facebook.
		 *
		 * **Note** - The app_id setting must be defined with the
		 * {@link WS.ShareController#networks.facebook#init} method before sharing.
		 */
		facebook: {
			data: {
				'name': 'Facebook'
			},

			/**
			 * @function WS.ShareController#networks.facebook#set
			 * @see WS.ShareController.Setter
			 */
			set: function() {
				this.settings = WS.ShareController.set.apply(this, [this.settings, arguments[0], arguments[1]]);
			},

			share: function(url, options, after) {
				var $para;

				if (!window.FB) {
					$(document.body).append(
						$("<div>", {
							'id': 'fb-root'
						})
					);

					this.init(function() {
						this.share(url, options);
					});
				} else {
					// do sharing
					options = window.ob_set(options || {}, {
						'text': '',
						'image': ''
					});

					if (options.text === "") {
						if (($para = $(".article .copy > p").first()).length ||
							($para = $(".article p").first()).length) {
							options.text = $para.text();
						}
					}

					window.FB.ui({
						'method': 'feed',
						'link': url,
						'caption': options.text,
						'name': options.title,
						'picture': options.image
					}, function(response) {
						if (typeof after === "function") {
							after();
						}
					});
				}
			},

			init: function(after) {
				var finish;

				if (!this.settings.app_id) {
					throw new Error("App ID must be defined with set('app_id', '{app_id}')");
				}

				finish = function() {
					window.FB.init({
						'appId': this.settings.app_id,
						'xfbml': true,
						'version': 'v2.0'
					});

					if (typeof after === "function") {
						after.apply(this);
					}
				};

				if (!window.FB) {
					window.fbAsyncInit = (function(fn, cls) {
						return function() {
							finish.apply(cls);

							if (typeof fn === "function") {
								fn();
							}
						};
					}(window.fbAsyncInit, this));

					// attach FB js
					$.getScript("//connect.facebook.net/en_US/sdk.js");
				} else {
					if (typeof after === "function") {
						after.apply(this);
					}
				}
			}
		},

		/**
		 * @class WS.ShareController#networks.linkedin
		 * @see WS.ShareController#networks
		 */
		linkedin: {
			data: {
				'name': 'LinkedIn'
			},

			settings: {
				'share_endpoint': 'http://www.linkedin.com/shareArticle?mini=true&',
				'window': {
					'width': 520,
					'height': 570
				}
			},

			set: function() {
				this.settings = WS.ShareController.set.apply(this, [this.settings, arguments[0], arguments[1]]);
			},

			share: function(url, options, after) {
				var params, key, window_settings;

				options = window.ob_set(options || {}, {
					'title': '',
					'source': '',
					'summary': ''
				});

				window_settings = [];
				params = [
					'url=' + window.encodeURIComponent(url)
				];

				for (key in options) {
					if (options.hasOwnProperty(key) && options[key] !== "") {
						params.push(key + '=' + window.encodeURIComponent(options[key]));
					}
				}

				url = this.settings.share_endpoint + params.join("&");

				if (this.settings.window) {
					for (key in this.settings.window) {
						if (this.settings.window.hasOwnProperty(key)) {
							window_settings.push(key + "=" + this.settings.window[key]);
						}
					}
				}

				window.open(url, "linkedinshare", window_settings.join(","));
			}
		},

		/**
		 * @class WS.ShareController#networks.googleplus
		 * @see WS.ShareController#networks
		 */
		googleplus: {
			data: {
				'name': 'Google+'
			},

			settings: {
				'intents_endpoint': 'https://plus.google.com/share',
				'window': {
					'width': 510,
					'height': 600
				}
			},

			set: function() {
				this.settings = ShareController.set.apply(this, [this.settings, arguments[0], arguments[1]]);
			},

			share: function(url, after) {
				var key,
				    window_settings = [];

				url = this.settings.intents_endpoint + '?url=' + url;

				if (this.settings.window) {
					for (key in this.settings.window) {
						if (this.settings.window.hasOwnProperty(key)) {
							window_settings.push(key + '=' + this.settings.window[key]);
						}
					}
				}

				window.open(url, 'googleplusshare', window_settings.join(','));
			}
		},

		/**
		 * @class WS.ShareController#networks.email
		 * @see WS.ShareController#networks
		 */
		email: {
			data: {
				'name': 'Email'
			},

			share: function(url, options, after) {
				options = window.ob_set(options || {}, {
					'to': 'email@user.com',
					'body': '',
					'subject': ''
				});

				location.href = "mailto:" + options.to +
					'?subject=' + window.encodeURIComponent(options.subject) +
					'&body=' + window.encodeURIComponent(options.body);
			}
		}
	};
}(window.jQuery, window.WS));