(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.SearchToggle = function() {
		var my;

		my = {
			ui: {
				$button: $('.header__search button'),
				$form: $('#headerSearchBar'),
				$iconClose: $('.header__search .icon-search'),
				$iconSearch: $('.header__search .icon-search-close'),
				$headerSearch: $('#headerSearch'),
				$navTrigger: $('.primary-nav-trigger'),
				$search: $('#headerSearch'),
				$searchbar: $('.header-search__input-group input')
			},

			enable: function() {
				my.ui.$button.on('click', function() {
					if (my.ui.$search.css('display') == 'none') {
						my.ui.$search.slideDown();
						my.ui.$iconClose.hide();
						my.ui.$iconSearch.show();
						my.ui.$searchbar.focus();

						if ($('body').hasClass('menu-open')) {
							my.ui.$navTrigger.click();
						}
					} else {
						my.ui.$search.slideUp();
						my.ui.$iconClose.show();
						my.ui.$iconSearch.hide();
					}
				})

				my.ui.$navTrigger.on('click', function() {
					if ($('body').hasClass('menu-open')) {
						if (my.ui.$headerSearch.is(':visible')) {
							my.ui.$button.click();
						}
					}
				})

				my.ui.$form.on('submit', function() {
					var val = my.ui.$searchbar.val();
					if (val == "") {
						return false;
					}
				})
			}
		}

		my.enable();
	};

	WS.SearchToggle();

})(window.jQuery, window.WS);
