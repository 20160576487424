(function($, WS) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.secondary_menu = function() {
		var my;

		my = {
			ui: {
				$nav: $('.secondary-navigation'),
				$toggle: $('.secondary-navigation__mobile-toggle'),
				$menu: $('.secondary-navigation__menu')
			},

			enable: function() {
				if (my.ui.$menu.length && my.ui.$toggle.length) {
					my.ui.$toggle.on('click', function() {
						if (my.ui.$nav.hasClass('active')) {
							my.ui.$nav.removeClass('active');
							my.ui.$menu.velocity('slideUp', {
								duration: 140,
								easing: 'easeInOutCirc'
							});
						} else {
							my.ui.$nav.addClass('active');
							my.ui.$menu.velocity('slideDown', {
								duration: 140,
								easing: 'easeInOutCirc'
							});
						}
					});
				}
			}	
		};

		my.enable();
	};

	WS.secondary_menu();

})(window.jQuery, window.WS);