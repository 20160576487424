/**
 * An HTML DOM element.
 * @typedef {Object} HTMLElement
 */

/**
 * The browser's built-in Event object
 * @typedef {Object} Event
 */
(function(window, document, WS, ga) {
	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	if (typeof Array.indexOf === "undefined") {
		Array.prototype.indexOf = function(test) {
			var a;

			for (a = 0; a < this.length; a += 1) {
				if (this[a] == test) {
					return a;
				}
			}

			return -1;
		};
	}

	if (typeof window.ob_set === "undefined") {
		window.ob_set = function(ob, defaults) {
			// set default object properties
			var key;

			for (key in defaults) {
				if (defaults.hasOwnProperty(key)) {
					if (defaults[key] !== null && defaults[key].constructor === Object &&
						(ob[key] === undefined || ob[key].constructor === Object)) {
						// traverse nested objects
						ob[key] = window.ob_set(ob[key] || {}, defaults[key]);
					} else if (ob[key] === undefined) {
						// replace missing objects
						ob[key] = defaults[key];
					}
				}
			}

			return ob;
		};
	}

	/**
	 * The full set of default options available to WS.Gat.
	 * @typedef {Object} Options
	 * @property {String} account - GA UA account number (provided by google). *Required*.
	 * @property {Boolean} ext_forward=true - Setting this value to `true` will forward the
	 * User Agent whenever a registered external link is clicked. Setting this to `false` will
	 * disable forwarding. (See 'Gotchas').
	 * @property {Array} hosts - An array of external hostnames, which when linked to, will be
	 * tracked using the cross-domain tracking functionality built into the search API.
	 * @property {Boolean} initial_track=true Track initial page load.
	 * @property {Boolean} demographics=false Set `true` to enable demographics reporting in your
	 * reports. This option has privacy implications so must be approved by DMP/AM before using.
	 * See here for more information: {@link https://support.google.com/analytics/answer/2819948?hl=en-GB}
	 * @property {Boolean} display_features=false Set `true` to enable advertising features in
	 * the Google Analytics host reporter. For more information, see here:
	 * {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/display-features}
	 * @property {Object} vectors - Default vector specific options. These can be changed to alter
	 * the way WS.Gat tracks automatically registered links by vector. The defaults for each vector
	 * are listed below:
	 * @property {Object} vectors.mailto
	 * @property {WS.Gat.types} vectors.mailto.type=page
	 * @property {Object} vectors.download
	 * @property {WS.Gat.types} vectors.download.type=page
	 * @property {Object} vectors.external
	 * @property {WS.Gat.types} vectors.external.type=page
	 * @memberof WS.Gat
	 */

	/**
	 * Calback for handling redirects.
	 * @callback WS.Gat.HandleRedirect
	 * @arg {string} uri - Target URI/URL - usually the location to which to redirect.
	 * @arg {Object} test - The `test` object contains useful information about the location.
	 * @arg {Boolean} test.external - The URL is external. I.e. the hostname does not match the
	 * document.
	 * @arg {Boolean} test.document - The URI is a document (PDF, Doc, etc). Documents are detected
	 * with the RegExp defined in {@link WS.Gat.Settings}.
	 * @arg {Object|undefined} register - The `register` Object, which contains information about the
	 * original HTMLElement element the track was registered against. This will be `undefined` if the
	 * track was manual.
	 * @arg {HTMLElement} register.element - The original DOM element.
	 * @arg {Event} register.event - The Event object, if applicable.
	 * @arg {WS.Gat.types} register.type - One of the {@link WS.Gat.types} types.
	 * @arg {Object} register.data - The original data sent to the registry for tracking.
	 * @this WS.Gat
	 */

	/**
	 * @class WS.Gat
	 * @version 1.0.0
	 * @arg {WS.Gat.Options} options An options object.
	 * @description
	 * The WSGat class is an extension/wrapper to the google analytics
	 * universal tracking API. When installed, provides a simple to use
	 * and semi-automated tracking system for all Whitespace developed sites.
	 * On creation, WSGat will scan all of the existing links (`a` and `area`) and apply tracking
	 * events accordingly. Links registered in this manner are called _passive_ links throughout
	 * the documentation. An _active_ or _manual_ link is tracked by the host code using either the
	 * {@link WS.Gat#track} or {@link WS.Gat#track_event} methods.
	 * #### Tracking 'gotchas'
	 * In addition to tracking your links, both WS.Gat and the google analytics API may produce some
	 * otherwise unexpected behaivour. Below are a few things to watch out for when implementing
	 * tracking:
	 * ##### External links
	 * External links are tracked by using two different methods. The reason for this is that when
	 * clicking on an external link, the browser tends to want to make and complete an HTTP request for
	 * that link, which can sometimes override the tracking request. Especially if sent synchronously.
	 *
	 * The first method is achieved by a timeout. When tracking passive external
	 * link clicks, WS.Gat firstly stops the default action (to forward to the page), performs a track,
	 * and then forwards on to the link after a timeout. The second method is to list the external domain
	 * within `hosts`, which means the link is dealt with as a cross-domain link, controlled by the GA API.
	 * The GA API tracks and manages forwarding to the external link internally.
	 *
	 * Both methods will forward onto the link - only if the link is being tracked passively. This means that
	 * if the {@link WS.Gat#track} method is used, the link will be tracked, but forwarding must be handled
	 * within your own code. Keep in mind that if you are creating external links that provide non-default
	 * behaivour (such as opening in a new window), they should not be tracked passively, as WS.Gat will
	 * forward on to the resulting URI automatically. This will have the unwanted effect of your original
	 * event running as well as the document switching to the requested URI.
	 *
	 * See below for stopping passive tracking on links.
	 *
	 * ##### Document links
	 * Links to documents (as validated by the regular expression in {@link WS.Gat.settings} are also forwarded
	 * onto within WS.Gat. This is to ensure that the track completes before the document is requested.
	 * If you have a document link that is not being tracked as such, or a link that is being mistakenly
	 * read as a document, you should alter the regex parameters accordingly.
	 *
	 * ##### The `no-track` attribute
	 * You can optionally apply a `rel` attribute to anchors and areas with the value containing `no-track`.
	 * WS.Gat will ignore these links when creating passive tracking events. Of course, these links should
	 * then be managed manually if you wish to track their requests. This can be done using the
	 * {@link WS.Gat#track} and {@link WS.Gat#track_event} methods.
	 *
	 * ##### The {@link WS.Gat#deregister} method
	 * If WS.Gat has already been initialised and the link did not have the `no-track` relation, you can remove
	 * links using the {@link WS.Gat#deregister} method. This will remove a link from the WSGat database and disable
	 * tracking on it.
	 * @example
	 * // instantiate class
	 * gat = WS.Gat({
	 * 	'account': 'UA-XXXXXXXX-X', // google UA ID (required)
	 *	'hosts': [ // optional external sites to track by x-domain
	 * 		'www.myothersite.com'
	 * 	]
	 * });
	 */
	WS.Gat = function(options) {
		var cls, my;

		// create class & instance
		cls = {
			options: (options || {}),
			active: false,

			/**
			 * @function WS.Gat#register
			 * @arg {HTMLElement|HTMLElement[]} elements - A single {@link HTMLElement} or array of {@link HTMLElement} elements
			 * to register automatic tracking against.
			 * @arg {WS.Gat.types} type - One of the {@link WS.Gat.types} types.
			 * @arg {Object} data - Options for VPV/event tracking.
			 * @arg {String} [data.uri] - The fully qualified URI to track. Can be either an internal or
			 * external URI.
			 * @arg {String} data.category - The event category. *Required for event types.*
			 * @arg {String} data.action - The event action. *Required for event types.*
			 * @arg {String} [data.label] - The event label.
			 * @arg {Number} [data.value] - A value associated with the event label.
			 * @description
			 * Registers the `click` event type to the passed element(s). When this event is
			 * fired, a single track will take place. In the case of `page` tracking, all arguments
			 * except for `elements` are optional, although if you wish to customise the URL that is
			 * tracked, an additional `data` Object should be specified.
			 *
			 * If an {@link HTMLElement} is already in the registry, it will be removed before being
			 * re-inserted.
			 *
			 * At the moment, the `click` event is the only event supported.
			 *
			 * @see {@link WS.Gat#deregister} for de-registration of elements.
			 * @see {@link http://code.google.com/apis/analytics/docs/gaJS/gaJSApiEventTracking.html}
			 * for more information on event tracking.
			 */
			register: function() {
				return my.register.apply(this, arguments);
			},

			/**
			 * @function WS.Gat#deregister
			 * @arg {HTMLElement} element - The HTMLElement element to deregister.
			 * @description
			 * Removes the specified HTMLElement from the internal element registry. The element will
			 * no longer be tracked when the appropriate event is fired upon it.
			 *
			 * Returns `true` if the element was successfully found and deregistered, `false` otherwise.
			 *
			 * ##### Tracking unregistered elements
			 *
			 * It should go without saying that once deregistering an element, it will not be tracked
			 * automatically. You can manually track the element using the {@link WS.Gat#track} or
			 * {@link WS.Gat#track_event} methods.
			 *
			 * @see {@link WS.Gat#register} for registration of elements.
			 * @see {@link http://code.google.com/apis/analytics/docs/gaJS/gaJSApiEventTracking.html}
			 * for more information on event tracking.
			 */
			deregister: function() {
				return my.deregister.apply(this, arguments);
			},

			/**
			 * @function WS.Gat#track
			 * @arg {String} uri - The URI/URL to track and forward onto in the case of external URLs.
			 * @arg {String} [track_uri] - Overrides `uri` for tracking when an external URL
			 * is specified. WSGat will still forward on to the URL specified in `uri`.
			 * @arg {Event} event - When an {@link Event} object is specified, the link's default
			 * action will be stopped for external links & document links, and forwarding will be
			 * performed either by cross-domain linking or after a timeout.
			 * @returns {Boolean|undefined} `true` on successful track (when run manually), `false`
			 * when triggered by an event (regardless of track status) and `undefined` when called
			 * before WS.Gat is fully active.
			 * @description
			 * Note: The cross domain link tracking will automatically forward to the tracked URI
			 * regardless of any WSGat settings. Do not use this if you wish to execute other code
			 * or remain on the page after tracking.
			 */
			track: function(uri, track_uri, event) {
				return my.track.apply(this, [uri, track_uri, event]);
			},

			/**
			 * @function WS.Gat#track_event
			 * @arg {String} category - Event category.
			 * @arg {String} action - Event action (such as 'play' for a video).
			 * @arg {String} [label] - Optional label for the event
			 * @arg {Number} [value=0] - Optional numeric value for the event
			 * @arg {Boolean} [runonce=false] - If set to `true`, will only run this event once if
			 * the same previous four arguments are sent again.
			 * @returns {Boolean} `true` on success, `false` on failure, or when called before
			 * WS.Gat is active.
			 */
			track_event: function() {
				return my.track_event.apply(this, arguments);
			},

			/**
			 * @function WS.Gat#track_social
			 * @arg {String} network - Network name (e.g. 'Facebook')
			 * @arg {String} action - Social action (e.g. 'Like')
			 * @arg {String} target - Social target URL or descriptor
			 * @arg {String} path=location - Page path
			 * @description
			 * Tracks a social event using the {@link https://developers.google.com/analytics/devguides/collection/gajs/gaTrackingSocial?csw=1|_trackSocial} method
			 * @see https://developers.google.com/analytics/devguides/collection/gajs/gaTrackingSocial?csw=1
			 */
			track_social: function() {
				return my.track_social.apply(this, arguments);
			},

			/**
			 * @function WS.Gat#set_redirect_handler
			 * @arg {WS.Gat.HandleRedirect} fn - The function to register as a redirect handler.
			 * @description
			 * Registers a custom function for use as a redirect handler within WS.Gat.
			 *
			 * Redirects as standard are handled by a simple method which loads the URI passed
			 * into the same window after a small timeout. This method can now be overridden by
			 * your own code.
			 *
			 * You are free to do whatever you want with this information, but ultimately should
			 * result in the URI/URL being loaded in some way.
			 */
			set_redirect_handler: function() {
				return my.set_redirect_handler.apply(this, arguments);
			}
		};

		// create private methods
		my = {
			enable: function() {
				// enables the class (after initialization)
				var links, i, href, protocol, rel, a;

				cls.options = window.ob_set(cls.options, {
					'vectors': {
						'mailto': {
							'type': WS.Gat.types.page
						},
						'download': {
							'type': WS.Gat.types.page
						},
						'external': {
							'type': WS.Gat.types.page
						}
					},
					'initial_track': true,
					'ext_forward': true,
					'demographics': false,
					'display_features': false
				});

				if (cls.options.hosts === undefined) {
					cls.options.hosts = [];
				}

				// include API
				(function(id) {
					var element, script, date;

					window.GoogleAnalyticsObject = id;

					window[id] = window[id] || function() {
						if (window.ga !== ga) {
							ga = window.ga;
							ga.apply(window, arguments);
						} else {
							(window[id].q = window[id].q || []).push(arguments);
						}
					};

					window[id].l = (new Date()).valueOf();

					ga = window[id];

					element = document.createElement("script");
					script = (document.getElementsByTagName("script"))[0];

					element.async = 1;
					element.src = "//www.google-analytics.com/analytics.js";
					script.parentNode.insertBefore(element,script);
				})("ga");

				if (typeof cls.options.account === "string" && cls.options.account !== "") {
					ga("create", cls.options.account, "auto", {
						'allowLinker': true
					});
				} else {
					throw "Invalid/undefined google account number supplied";
				}

				if (cls.options.display_features) {
					ga('require', 'displayfeatures');
				}

				if (cls.options.initial_track) {
					ga("send", "pageview");

					my.log("Tracking initial page: '" + location.href + "'");
				}

				if (cls.options.hosts.length > 0) {
					ga("require", "linker");
					ga("linker:autoLink", cls.options.hosts);
				}

				my.observe(document, "click", my.event_handle);

				my.log("Extended universal tracking initialized");

				// create database
				my.registry = [];

				// scan document for links
				links = document.getElementsByTagName("a");

				for (i = 0; i < links.length; i += 1) {
					a = links[i];

					try {
						if (a.getAttribute) {
							href = a.getAttribute("href");
							rel = a.getAttribute("rel");
						} else {
							href = a.readAttribute("href");
							rel = a.readAttribute("rel");
						}

						protocol = a.protocol;
					} catch(e) {
						href = null;
						rel = null;
					}

					if (href !== null && (href.indexOf("#") !== 0 && (rel === null || !rel.match(/no-track/)))) {
						switch (protocol) {
							case "mailto:":
								// register mailto
								if (cls.options.vectors.mailto.type === WS.Gat.types.page) {
									// track as a pageview
									cls.register(a, WS.Gat.types.page, {
										'uri': href,
										'track_uri': my.prefixURI(href.substring(7), '/mailto')
									});
								} else if (cls.options.vectors.mailto.type === WS.Gat.types.event) {
									// track as an event
									cls.register(a, WS.Gat.types.event, {
										'category': 'mailto',
										'action': href.substring(7)
									});
								}
								break;

							case "http:":
							case "https:":
							case "ftp:":
								// register http/ftp links
								if (href.match(WS.Gat.settings.doc_re)) {
									// track documents
									if (cls.options.vectors.download.type === WS.Gat.types.page) {
										// track as a pageview
										cls.register(a, WS.Gat.types.Page, {
											'uri': href,
											'track_uri': my.prefixURI(href, '/downloads')
										});
									} else if (cls.options.vectors.download.type === WS.Gat.types.event) {
										// track as an event
										cls.register(a, WS.Gat.types.event, {
											'category': 'Download',
											'action': 'Click',
											'label': href.substring(7)
										});
									}
								} else if (my.test_uri(href).external) {
									// track external links
									if (cls.options.vectors.external.type === WS.Gat.types.page) {
										// track as a pageview
										cls.register(a, WS.Gat.types.Page, {
											'uri': href
										});
									} else if (cls.options.vectors.external.type === WS.Gat.types.event) {
										// track as an event
										cls.register(a, WS.Gat.types.event, {
											'category': 'External',
											'action': 'Click',
											'label': href
										});
									}
								}

								break;
						}
					}
				}

				cls.active = true;
			},

			register: function(elements, type, data, event) {
				var a, b, element, href, element_string;

				type = type || WS.Gat.types.page;
				event = event || "click";
				data = data || {};

				if (typeof elements !== "object" || !elements.length) {
					elements = [elements];
				}

				for (a = 0; a < elements.length; a += 1) {
					// loop through and register element(s)
					element = elements[a];

					// check the element isn't already registered
					for (b = 0; b != my.registry.length; b += 1) {
						if (my.registry[b].element === element) {
							my.registry.splice(b, 1);

							element_string = element.tagName;

							if (typeof element.id === "string") {
								element_string += "#" + element.id;
							}

							if (element.className !== "") {
								element_string += "." + element.className;
							}

							my.log("Element " + element_string + " is already registered - removing from registry.");
							break;
						}
					}

					try {
						if (element.tagName === "A" || element.tagName === "AREA") {
							if (element.getAttribute) {
								href = element.getAttribute("href");
							} else {
								href = element.readAttribute("href");
							}

							data = window.ob_set(data, {
								'uri': href
							});
						}
					} catch(e) {
						throw "Invalid/undefined element passed";
					}

					if (type === WS.Gat.types.page) {
						if (data.uri !== undefined) {
							my.registry.push({
								'element': element,
								'event': event,
								'type': type,
								'data': data
							});
						} else {
							throw "Invalid data for page tracking registration supplied." +
								" A minimum of 'uri' must be included in the data object for non link elements.";
						}
					} else if (type === WS.Gat.types.event) {
						if (data.category !== undefined && data.action !== undefined) {
							my.registry.push({
								'element': element,
								'event': event,
								'type': type,
								'data': data
							});
						} else {
							throw "Invalid data for event tracking registration supplied." +
								" A minimum of 'category' and 'action' must be included in the data object.";
						}
					}
				}
			},

			deregister: function(element) {
				var a, element_string;

				for (a = 0; a != my.registry.length; a += 1) {
					if (my.registry[a].element === element) {
						my.registry.splice(a, 1);

						element_string = element.tagName;

						if (typeof element.id === "string") {
							element_string += "#" + element.id;
						}

						if (element.className !== "") {
							element_string += "." + element.className;
						}

						my.log("Element " + element_string + " removed from registry.");
						return true;
					}
				}

				return false;
			},

			event_handle: function(event) {
				var a, element, register;

				event = event || window.event();

				if (event && event.type === "click") {
					// obtain element from event
					element = event.srcElement || event.target;

					// search for element in the registry
					while (element) {
						for (a = 0; a != my.registry.length; a += 1) {
							if (my.registry[a].element === element) {
								register = my.registry[a];
								break;
							}
						}

						if (register) {
							break;
						}

						element = element.parentNode;
					}

					// act upon data (ooh!)
					if (register) {
						switch (register.type) {
							case WS.Gat.types.page:
								return my.track(
									register.data.uri,
									register.data.track_uri,
									event,
									register
								);

							case WS.Gat.types.event:
								return my.track_event(
									register.data.category,
									register.data.action,
									(register.data.label ? register.data.label : undefined),
									(register.data.value ? register.data.value : undefined),
									Boolean(register.data.runonce)
								);
						}
					}
				}
			},

			track: function(uri, track_uri, event, register) {
				var test, target;

				if (!cls.active) {
					return;
				}

				if (typeof track_uri !== "string" || track_uri === "") {
					track_uri = uri;
				}

				test = my.test_uri(uri);
				target = cls;

				my.log(
					"Tracking" +
					(test.external ? " external" : "") + " VPV: '" + track_uri + "'"
				);

				if (test.external) {
					// external links
					if (event !== undefined && cls.options.ext_forward) {
						// event is specified - cancel default action
						if (event.preventDefault) {
							event.preventDefault();
						}

						if (typeof event.target !== "undefined") {
							target = event.target;
						}
					}

					if (test.hostname && cls.options.hosts.indexOf(test.hostname) === -1) {
						// hostname not in the x-hosts array
						ga("send", "pageview", my.prefixURI(track_uri, '/external'));

						if (event !== undefined && cls.options.ext_forward) {
							my.redirect.apply(target, [uri, test, register]);
						}
					} else {
						// hostname in the x-hosts array - tracking is automatically linked
						my.redirect.apply(target, [uri, test, register]);
					}
				} else {
					// internal link
					if (test.document && event !== undefined) {
						// document link from a registered event
						if (event.preventDefault) {
							event.preventDefault();
						}
					}

					ga("send", "pageview", track_uri);

					if (test.document && event !== undefined) {
						my.redirect.apply(target, [uri, test, register]);
					}
				}

				if (test.external || test.document) {
					// return false for registered event tracks
					return (event === undefined);
				} else {
					// return true - default event action continues
					return true;
				}
			},

			track_event: function(category, action, label, value, runonce) {
				// track an event
				var identifier, tracked;

				identifier = category+"/"+action+"["+label+"]";
				tracked = false;

				if (!cls.active || (runonce && WS.Gat.tracked.indexOf(identifier) !== -1)) {
					return false;
				}

				if (label !== undefined && label !== null) {
					my.log("Tracking event: " + category + ", " + action + " (" + label + ": '" + value + "')");

					if (!isNaN(value) && value !== null) {
						value = parseInt(value, 10);
					} else {
						value = 0;
					}

					tracked = (ga("send", "event", category, action, label, value) === 0);
				} else {
					my.log("Tracking event: " + category + ", " + action);

					tracked = (ga("send", "event", category, action) === 0);
				}

				WS.Gat.tracked.push(identifier);

				return tracked;
			},

			track_social: function(network, action, target, path) {
				my.log("Tracking social action: " + network + ", " + action + " [target: '" + target + "', path: '" + path + "']");

				ga("send", "social", network, action, target, {
					'page': path
				});
			},

			test_uri: function(uri) {
				var result, hostname;

				result = {
					'external': false,
					'document': false
				};

				hostname = null;

				if (uri !== undefined) {
					hostname = uri.match(/:\/\/([^\/]*)/);

					// test for external URI
					if (hostname !== null &&
						(hostname[1].length > 3 && hostname[1] !== location.hostname && hostname[1] !== "")) {
						result.external = true;
					}

					// test for document link
					if (uri.match(WS.Gat.settings.doc_re)) {
						result.document = true;
					}
				}

				result.hostname = (hostname !== null ? hostname[1] : null);

				return result;
			},

			log: function() {
				// log event to the console (if active)
				var a;

				if (typeof console !== "undefined") {
					for (a = 0; a != arguments.length; a += 1) {
						console.log(arguments[a]);
					}
				}
			},

			redirect: function(uri, test) {
				window.setTimeout(function() {
					location.href = uri;
				}, WS.Gat.settings.transfer_timeout);
			},

			set_redirect_handler: function(fn) {
				if (typeof fn === "function") {
					my.redirect = fn;
					return my.redirect;
				}

				return false;
			},

			observe: function(element, event, fn) {
				if (element.addEventListener) {
					element.addEventListener(event, fn, false);
				} else if (element.attachEvent) {
					element.attachEvent("on" + event, fn);
				}
			},

			prefixURI: function(uri, prefix) {
				if (uri.charAt(0) !== "/") {
					// uri has no leading slash - add to concat'ed string
					return prefix + "/" + uri;
				} else {
					return prefix + uri;
				}
			}
		};

		// run post-initialization method
		my.enable();

		return cls;
	};

	/**
	 * @static types
	 * @memberof WS.Gat
	 * @property {string} page - 'Page' type tracking. for VPVs and page loads.
	 * @property {string} event - 'Event' type tracking. For interaction events.
	 */
	WS.Gat.types = {
		// track types
		'page': 0,
		'event': 1
	};

	/**
	 * @static settings
	 * @memberof WS.Gat
	 * @property {string} doc_re - Regex object defining a matching regex for document files.
	 * @property {string} transfer_timeout - In milliseconds, the amount of time before an automatic
	 * transfer to the resultant URI/URL takes place.
	 * @example
	 * // default RegExp for doc_re
	 * /\.(?:docx?|eps|je?pg|png|svg|xls|ppt|pdf|xls|zip|txt|vsd|vxd|rar|exe|wma|mov|avi|wmv|mp3)($|\&|\?)/i
	 */
	WS.Gat.settings = {
		// settings (for all instances)
		'doc_re': /\.(?:docx?|eps|jpe?g|png|svg|xls|ppt|pdf|xls|zip|txt|vsd|vxd|rar|exe|wma|mov|avi|wmv|mp3)($|\&|\?)/i,
		'transfer_timeout': 100
	};

	/**
	 * Contains an Array of identifiers for the tracked events. Used internally to ensure 'once only'
	 * tracks are correctly monitored.
	 * @static tracked
	 * @memberof WS.Gat
	 * @type Array
	 */
	WS.Gat.tracked = [];
}(window, document, window.WS));