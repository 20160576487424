(function($, WS) {
    "use strict";

    /**
     * @namespace WS
     */
    if (typeof WS === "undefined") {
        window.WS = {};
        WS = window.WS;
    }

    WS.search_animation = function() {

        var my = {
            enable: function() {
                my.selectors = {
                    container: "[data-search-animation]",
                    text: "[data-search-animation-text]",
                    wrapper: "[data-search-animation-text-wrapper]",
                    input: "[data-search-animation-input]"
                };

                my.classes = {
                    hidden: "is-hidden"
                };

                my.init();
            },

            init: function() {
                $('.glossary').find(my.selectors.container).each(function() {
                    var $container, $wrapper, $text, $input, strings, animation;

                    $container = $(this);
                    $wrapper = $container.find(my.selectors.wrapper);
                    $text = $container.find(my.selectors.text);
                    $input = $container.find(my.selectors.input);
                    strings = $text.data("search-animation-text");

                    $input.removeAttr("placeholder");
                    $wrapper.removeClass(my.classes.hidden);

                    if (strings.length) {
                        $input.removeAttr("placeholder");
                        $wrapper.removeClass(my.classes.hidden);

                        window.searchanimation = new TypeIt(my.selectors.text, {
                            strings: strings,
                            cursor: false,
                            breakLines: false,
                            loop: true,
                            loopDelay: 5000,
                            nextStringDelay: 2000
                        });

                        $input.on("focus", function() {
                            window.searchanimation.freeze();
                            $wrapper.addClass(my.classes.hidden);
                        });

                        $input.on("blur", function() {
                            if (!$(this).val().length) {
                                window.searchanimation.unfreeze();
                                $wrapper.removeClass(my.classes.hidden);
                            }
                        });
                    }
                });
            }
        };

        my.enable();
    };

    WS.search_animation();
})(window.jQuery, window.WS);
