(function($, WS) {
	'use strict';

	WS.event_video = function() {
		var my;

			my = {
				ui: {
					$full_width_video: $('.full-width-video')
				},

				selectors: {
					placeholder_container: '.video-placeholder',
					placeholder_image: '.video-placeholder img',
					shader: '.video-shader',
					trigger_play: '.video-placeholder a',
					video_panel: '.event-video-panel',
					video: '.event-video'
				},

				enable: function() {
					if (my.ui.$full_width_video.length) {
						var video_url = $(my.selectors.video).data('video');

						$(my.selectors.trigger_play).on('click', function(e) {
							e.preventDefault();

							$(my.selectors.placeholder_container).css('background', '#fff');
							$(my.selectors.placeholder_image).css('display', 'none');
							$(my.selectors.video).css('display', 'flex');

							$(my.selectors.video).append(
								'<iframe src="' + video_url + 
								'" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
						});
					}
				}
			};
		my.enable();
	};

	WS.event_video();
	
})(window.jQuery, window.WS);